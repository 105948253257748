import React from "react";
import Layout from "../../../components/Layout";

const PrivacyPolicyPage = () => {
  const pageTitle = "Privacy Policy | Unschool";
  const pageDescription =
    "If you have any questions or concerns regarding this Privacy Policy, you should contact our Customer Support Desk at support@unschool.in";

  return (
    <Layout title={pageTitle} description={pageDescription}>
      <div className="policies-container">
        <h2 className="title" style={{ marginBottom: "20px" }}>
          Privacy Policy & Payment Terms
        </h2>
        <p>Posted as of 27 Apr, 2022</p>
        <p>Last updated as of 27 Apr, 2022.</p>
        <div
          dangerouslySetInnerHTML={{
            __html: `<p><span style="font-weight: 400;">We, </span><strong>Edupolis </strong><strong>Education Technology</strong><strong> Private Limited</strong><span style="font-weight: 400;">, Registered</span><span style="font-weight: 400;"> under the provisions of the Companies Act 2013, having its registered office at</span><span style="font-weight: 400;">&nbsp;</span><strong><em>&ldquo;S2, Cresent Towers, Pragathi Nagar, Kukatpally, Hyderabad 500090, Telangana</em></strong><strong><em>&rdquo;</em></strong><span style="font-weight: 400;"> represented by its Directors, hereinafter referred to as the </span><strong>&ldquo;Company&rdquo;</strong><span style="font-weight: 400;"> (where such expression shall, unless repugnant to the context thereof, be deemed to include its respective legal heirs, representatives, administrators, permitted successors and assigns).</span></p>
<p><span style="font-weight: 400;">The creator of this Privacy Policy ensures a steady commitment to Your privacy with regard to the protection of your invaluable information.</span></p>
<p><span style="font-weight: 400;">This privacy policy contains information about the Website </span><strong>www.unschool.in</strong><span style="font-weight: 400;"> (hereinafter referred to as the &ldquo;</span><strong>Platform</strong><span style="font-weight: 400;">&rdquo;).</span></p>
<p><span style="font-weight: 400;">In order to provide You with Our uninterrupted use of services, We may collect and, in some circumstances, disclose information about you with your permission. To ensure better protection of Your privacy, We provide this notice explaining Our information collection and disclosure policies, and the choices You make about the way Your information is collected and used.&nbsp;&nbsp;</span></p>
<p><strong>This Privacy Policy shall be in compliance with the General Data Protection Regulation (GDPR) in effect from May 25, 2018, and any and all provisions that may read to the contrary shall be deemed to be void and unenforceable as of that date. If you do not agree with the terms and conditions of our Privacy Policy, including in relation to the manner of collection or use of your information, please do not use or access the Site. If you have any questions or concerns regarding this Privacy Policy, you should contact our Customer Support Desk at support@unschool.in.</strong></p>
<p><span style="font-weight: 400;">ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATORS OF THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.</span></p>
<ol>
<ol>
<li style="font-weight: 400;"><strong>DEFINITIONS</strong></li>
<ol>
<li style="font-weight: 400;"><em><span style="font-weight: 400;">&ldquo;We&rdquo;</span></em><span style="font-weight: 400;">, </span><em><span style="font-weight: 400;">&ldquo;Our&rdquo;</span></em><span style="font-weight: 400;">, and </span><em><span style="font-weight: 400;">&ldquo;Us&rdquo;</span></em><span style="font-weight: 400;"> shall mean and refer to the Domain and/or the Company, as the context so requires</span><strong>.</strong></li>
<li style="font-weight: 400;"><em><span style="font-weight: 400;">&ldquo;You&rdquo;</span></em><span style="font-weight: 400;">, </span><em><span style="font-weight: 400;">&ldquo;Your&rdquo;</span></em><span style="font-weight: 400;">, </span><em><span style="font-weight: 400;">&ldquo;Yourself&rdquo;</span></em><span style="font-weight: 400;">,</span><em><span style="font-weight: 400;"> &ldquo;User&rdquo;</span></em><span style="font-weight: 400;">, shall mean and refer to natural and legal individuals who use the Platform and who is competent to enter into binding contracts, as per Indian laws.</span></li>
</ol>
</ol>
</ol>
<p>&nbsp;</p>
<ul>
<li style="font-weight: 400;"><em><em><span style="font-weight: 400;">&ldquo;Services&rdquo;</span></em><span style="font-weight: 400;"> shall refer to Services provided by the Company, for users of the platform to host their own online courses and earn a royalty for each sale the users can access the content through videos, texts, and slides The detailed explanation shall be provided under the heading &ldquo;</span><em><span style="font-weight: 400;">Description of Service&rdquo; </span></em><span style="font-weight: 400;">of&nbsp; Terms of Use.</span></em></li>
</ul>
<p>&nbsp;</p>
<ol>
<ol>
<ol>
<li style="font-weight: 400;"><em><span style="font-weight: 400;">&ldquo;Third Parties&rdquo;</span></em><span style="font-weight: 400;"> refer to any Application, company or individual apart from the User, Vendor and the creator of this Application.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The term </span><em><span style="font-weight: 400;">&ldquo;Platform&rdquo;</span></em><span style="font-weight: 400;"> refers to the Website/Domain created by the Company which provides the Client to avail services of the Company through the use of the platform.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The term &ldquo;</span><em><span style="font-weight: 400;">User</span></em><span style="font-weight: 400;">&rdquo; refers to the individuals who will be using the platform to post their teachings and the individuals who are using the platform to learn from the videos that have been uploaded.</span></li>
<li style="font-weight: 400;"><em><span style="font-weight: 400;">&ldquo;Personal Information&rdquo;</span></em><span style="font-weight: 400;"> shall mean and refer to any personally identifiable information that We may collect from You such as Name, Email Id, Mobile number, Password, Photo etc.&nbsp; For removal of any doubts, please refer to Clause 2.</span></li>
</ol>
</ol>
</ol>
<p>&nbsp;</p>
<ul>
<li><strong><strong>INFORMATION WE COLLECT</strong></strong></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">We are committed to respecting Your online privacy. We further recognize Your need for appropriate protection and management of any Personal Information You share with us. We may collect the following information:&nbsp;</span></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">Personal data such as, but not limited to, Name, Email Id, Mobile number, Password, Age, Address, etc;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Tracking Information such as, but not limited to the IP address of your device and Device ID when connected to the Internet. This information may include the URL that you just came from (whether this URL is on the Platform or not), which URL you next go to (whether this URL is on the Platform or not), your computer or device browser information, and other information associated with your interaction with the Platform;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Details of Platform usage for analytics.</span></li>
</ol>
<p><span style="font-weight: 400;">This privacy policy also applies to data we collect from users who are not registered as members of this Platform, including, but not limited to, browsing behaviour, pages viewed etc. We also collect and store personal information provided by You from time to time on the Platform. We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including:&nbsp;</span></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">To enable the provision of services opted for by you;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">To enable the viewing of content in your interest;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">To communicate the necessary account and service related information from time to time;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">To allow you to receive quality customer care services and data Collection;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">To comply with applicable laws, rules and regulations;</span></li>
</ol>
<p><span style="font-weight: 400;">Where any service requested by You involves a third party, such information as is reasonably necessary by the Company to carry out Your service request may be shared with such third party. We also do use your contact information to send you offers based on your interests and prior activity and also to view the content preferred by you. The Company may also use contact information internally to direct its efforts for service improvement but shall immediately delete all such information upon withdrawal of your consent for the same through the &lsquo;</span><strong>unsubscribe</strong><span style="font-weight: 400;">&rsquo; button or through an email to be sent </span><strong>support@unschool.in</strong></p>
<p><span style="font-weight: 400;">To the extent possible, we provide You with the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular service or feature on the Platform and opt-out of any non-essential communications from the platform.&nbsp;</span></p>
<p><span style="font-weight: 400;">Further, transacting over the internet has inherent risks which can only be avoided by you following security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong><strong>OUR USE OF YOUR INFORMATION</strong></strong></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">The information provided by you shall be used to provide and improve the service for you and all users.</span></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">For maintaining an internal record.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">For enhancing the Services provided.&nbsp;</span></li>
</ol>
<p><span style="font-weight: 400;">For more details about the nature of such communications, please refer to our Terms of Service. Further, your personal data and Sensitive Personal data may be collected and stored by Us for internal record.&nbsp;</span></p>
<p><span style="font-weight: 400;">We use Your tracking information such as IP addresses, and or Device ID to help identify you and to gather broad demographic information and make further services available to you.&nbsp;</span></p>
<p><span style="font-weight: 400;">We will not sell, license or trade Your personal information. We will not share your personal information with others unless they are acting under our instructions or we are required to do so by law.&nbsp;</span></p>
<p><span style="font-weight: 400;">Information collected via Our server logs includes users' IP addresses and the pages visited; this will be used to manage the web system and troubleshoot problems. We also use third-party analytics, tracking, optimization and targeting tools to understand how users engage with our Platform so that we can improve it and cater personalized content/ads according to their preferences.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong><strong>HOW INFORMATION IS COLLECTED</strong></strong></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Before or at the time of collecting personal information, we will identify the purposes for which information is being collected. If the same is not identified to you, you have the right to request the Company to elucidate the purpose of collection of said personal information, pending the fulfilment of which you shall not be mandated to disclose any information whatsoever.&nbsp;</span></p>
<p><span style="font-weight: 400;">We will collect and use your personal information solely with the objective of fulfilling those purposes specified by us, within the scope of the consent of the individual concerned or as required by law. We will only retain personal information as long as necessary for the fulfilment of those purposes. We will collect personal information by lawful and fair means and with the knowledge and consent of the individual concerned.&nbsp;</span></p>
<p><span style="font-weight: 400;">Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.&nbsp;</span></p>
<ol>
<li style="font-weight: 400;"><strong>EXTERNAL LINKS ON THE PLATFORM</strong></li>
</ol>
<p><span style="font-weight: 400;">The Platform may include advertisements, hyperlinks to other websites, applications, content or resources. We have no control over any websites or resources, which are provided by companies or persons other than Us. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and do not endorse any advertising, services/products or other materials on or available from such platform or resources. You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, products or other materials on, or available from, such websites or resources. These external websites and resource providers may have their own privacy policies governing the collection, storage, retention and disclosure of Your Personal Information that You may be subject to. We recommend that you enter the external Website and review their privacy policy.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong><strong>&nbsp;COOKIES</strong></strong></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">We use data collection devices such as &ldquo;cookies&rdquo; on certain pages of our Websites. &ldquo;Cookies&rdquo; are small files sited on your hard drive that assist us in providing customized services. We also offer certain features that are only available through the use of a &ldquo;cookie&rdquo;. Cookies can also help us provide information which is targeted to your interests. Cookies may be used to identify logged in or registered users. Our Website uses session cookies to ensure that you have a good experience. These cookies contain a unique number, your 'session ID', which allows our server to recognize your computer and 'remember' what you've done on the site. The benefits of this are:</span></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">You only need to log in once if you're navigating secure areas of the site</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Our server can distinguish between your computer and other users, so you can see the information that you have requested.</span></li>
</ol>
<p><span style="font-weight: 400;">You can choose to accept or decline cookies by modifying your browser settings if you prefer. This may prevent you from taking full advantage of the Website. We also use various third-party cookies for usage, behavioral analytics and preferences data. The following are the different types of cookies used on the Website:</span></p>
<ol>
<li style="font-weight: 400;"><strong>Authentication cookies</strong><span style="font-weight: 400;">: To identify the user and share the content that he or she requested.</span></li>
<li style="font-weight: 400;"><strong>Functionality cookies</strong><span style="font-weight: 400;">: For the customized user experience and resuming past course progress.</span></li>
<li style="font-weight: 400;"><strong>Tracking, optimization, and targeting cookies</strong><span style="font-weight: 400;">: To capture usage metric on the device, operating system, browser, etc. To capture behavioral metrics for better content delivery. To cater and suggest most suited products and services.</span></li>
</ol>
<ol>
<li style="font-weight: 400;"><strong>YOUR RIGHTS</strong></li>
</ol>
<p><span style="font-weight: 400;">Unless subject to an exemption, you have the following rights with respect to your personal data:&nbsp;</span></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">The right to request a copy of your personal data which we hold about you;&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The right to request for any correction to any personal data if it is found to be inaccurate or out of date;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The right to withdraw Your consent to the processing at any time;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The right to object to the processing of personal data;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The right to lodge a complaint with a supervisory authority.&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The right to obtain information as to whether personal data are transferred to a third country or to an international organization.</span></li>
</ol>
<p><span style="font-weight: 400;">Where you hold an account with any of our services, you are entitled to a copy of all personal data which we hold in relation to you. You are also entitled to request that we restrict how we use your data in your account when you log in.</span></p>
<ol>
<li style="font-weight: 400;"><strong>CONFIDENTIALITY</strong></li>
</ol>
<p><span style="font-weight: 400;">You further acknowledge that the Platform may contain information which is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party or use your e-mail address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation of such communications at any point of time.&nbsp;</span></p>
<ol>
<li style="font-weight: 400;"><strong>OTHER INFORMATION COLLECTORS&nbsp;</strong></li>
</ol>
<p><span style="font-weight: 400;">Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our Platform or on other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their own privacy policies. Since we do not control the privacy policies of the third parties, you are subject to ask questions before you disclose your personal information to others.&nbsp;</span></p>
<ol>
<li style="font-weight: 400;"><strong>OUR DISCLOSURE OF YOUR INFORMATION&nbsp;</strong></li>
</ol>
<p><span style="font-weight: 400;">We may host surveys from survey creators for our platform who are the owners and users of your survey responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be disclosed to survey creators. Please contact the survey creator directly to better understand how they might share your survey responses.</span></p>
<p><span style="font-weight: 400;">Information collected will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any rules made thereunder or any other law for the time being in force.</span></p>
<p><span style="font-weight: 400;">Due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third parties. Therefore, although we use industry-standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private. We do however assure you that any and all disclosure of your personally identifiable information shall be personally intimated to you through an email sent to your provided email address.&nbsp;</span></p>
<p><span style="font-weight: 400;">As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifiable information may be disclosed:&nbsp;</span></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">External Service Providers: There may be a number of services offered by external service providers that help you use our Platform. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or grant them permission to collect information about you, then their use of your information is governed by their privacy policy.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Law and Order: We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us or you to legal liability.&nbsp;</span></li>
</ol>
<ol>
<li style="font-weight: 400;"><strong>ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</strong></li>
</ol>
<p><span style="font-weight: 400;">Following registration, you can review and change the information you submitted at the stage of registration, except Email ID and mobile number. An option for facilitating such change shall be present on the Platform and such change shall be facilitated by the User. If you change any information, we may or may not keep track of your old information. We will retain in our files, information you have requested to remove, for certain circumstances such as to resolve disputes, troubleshoot problems and enforce our terms and conditions. Other prior information shall be completely removed from our databases, including those stored in &lsquo;back up&rsquo; systems. If you believe that any information, we are holding on you is incorrect or incomplete, the User needs to remediate, and promptly correct any such incorrect information.</span></p>
<ol>
<li style="font-weight: 400;"><strong>&nbsp;CONTROL OF YOUR PASSWORD</strong></li>
</ol>
<p><span style="font-weight: 400;">You are entirely responsible for maintaining the confidentiality of your password. It is important that you protect it against unauthorized access of your account and information by choosing your password carefully and keeping your password and computer secure by signing out after using our services.&nbsp;</span></p>
<p><span style="font-weight: 400;">You agree not to use the account, username, email address or password of another Member at any time or to disclose your password to any third party. You are responsible for all actions taken with your login information and password, including fees. If you lose control of your password, you may lose substantial control over your personally identifiable information and may be subject to legally binding actions taken on your behalf. Therefore, if your password has been compromised for any reason, you should immediately change your password. You agree to notify us immediately if you suspect any consistent unauthorized use of your account or access to your password even after changing it.</span></p>
<ol>
<li style="font-weight: 400;"><strong>&nbsp;SECURITY</strong></li>
</ol>
<p><span style="font-weight: 400;">We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the Company. We follow generally accepted industry standards to protect the Personal Information submitted to us and information that we have accessed.</span></p>
<p><span style="font-weight: 400;">However, as effective as encryption technology is, no security system is impenetrable. Our Company cannot guarantee the security of our database, nor can we guarantee that information you provide won&rsquo;t be intercepted while being transmitted to the Company over the Internet.</span></p>
<ol>
<li style="font-weight: 400;"><strong>&nbsp;SEVERABILITY&nbsp;</strong></li>
</ol>
<p><span style="font-weight: 400;">Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall have no effect on the remaining paragraphs of this privacy policy.</span></p>
<ol>
<li style="font-weight: 400;"><strong>&nbsp;AMENDMENT&nbsp;</strong></li>
</ol>
<p><span style="font-weight: 400;">Our Privacy Policy may change from time to time. The most current version of the policy will govern our use of your information and will always be at the Platform. Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Platform.</span></p>
<ol>
<li style="font-weight: 400;"><strong>&nbsp;AUTOMATED DECISION MAKING&nbsp;</strong></li>
</ol>
<p><span style="font-weight: 400;">As a responsible Company, we do not use automatic decision-making or profiling.</span></p>
<ol>
<li style="font-weight: 400;"><strong>&nbsp;CONSENT WITHDRAWAL, DATA DOWNLOAD &amp; DATA REMOVAL REQUESTS</strong></li>
</ol>
<p><span style="font-weight: 400;">To withdraw your consent, or to request the download or delete your data with us for any or all our products &amp; services at any time, please email to </span><a href="mailto:support@unschool.in"><strong>support@unschool.in</strong></a></p>
<p>&nbsp;</p>
<ul>
<li><strong><strong>&nbsp;CONTACT US&nbsp;</strong></strong></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">If you have any questions or concerns regarding this privacy policy, you should contact us by sending an e-mail to please email to </span><strong>support@unschool.in/ admin@unschool.in</strong></p>
<p><span style="font-weight: 400;">Information provided on the website may not be 100% accurate and may be provided for promotional purposes of the business</span></p>
<p><br /><br /></p>
<p>&nbsp;</p>`,
          }}
        ></div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;
